<template>
  <div class=" home-container">
    <div class="d-flex align-items-center justify-content-between header-container">
      <div class="logo">
        <a href="https://biotochina.org/">BioToChina.org</a>
      </div>
      <el-menu
        :default-active="type"
        class="menu"
        :router="true"
        mode="horizontal"
      >
        <!--        <el-menu-item-->
        <!--          index="global_licensing_opportunities"-->
        <!--          :route="{name:'Assets Home Type',params:{type:'global_licensing_opportunities'}}"-->
        <!--        >-->
        <!--          Global Licensing Opportunities-->
        <!--        </el-menu-item>-->

        <!--        <el-menu-item-->
        <!--          index="china_innovation"-->
        <!--          :route="{name:'Assets Home Type',params:{type:'china_innovation'}}"-->
        <!--        >-->
        <!--          China Innovation-->
        <!--        </el-menu-item>-->
        <!--        <el-menu-item-->
        <!--          index="assets_apply"-->
        <!--          :route="{name:'Assets Apply'}"-->
        <!--        >-->
        <!--          Apply Online-->
        <!--        </el-menu-item>-->
        <!--        <el-menu-item disabled>-->
        <!--          <div class="nav-gap" />-->
        <!--        </el-menu-item>-->
        <el-submenu index="3">
          <template slot="title">
            {{ $i18n.locale | getLang }}
          </template>
          <el-menu-item @click="changeLocale('zh')">
            中文
          </el-menu-item>
          <el-menu-item @click="changeLocale('en')">
            English
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <router-view />
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

export default @Component({
  filters: {
    getLang (lang) {
      switch (lang) {
        case 'zh':
          return 'Language'
        case 'en':
          return '选择语言'
        default:
          return lang
      }
    }
  }
})
class HomePage extends Vue {
  get type () {
    return this.$route.params.type || this.$route.meta.index
  }

  changeLocale (locale) {
    this.$i18n.locale = locale
    localStorage.setItem('locale', locale)
    localStorage.setItem('fallbackLocale', locale)
  }
}
</script>
<style lang="scss" scoped>

:deep(.el-menu-item.is-active) {
  color: #db9620 !important;
}

.header-container {
  height: 60px;
  box-sizing: border-box;
  box-shadow: 0 1px 15px 0 #e0e0e0;
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 999;

  .logo {
    padding: 0 20px;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: bold;

    a {
      color: #303133;
      text-decoration: none;
    }
  }

  .menu {
    border-bottom: none;
  }

  .nav-gap {
    position: relative;
    width: 1px;
    height: 100%;
    padding: 0 20px;

    &:before {
      content: "";
      position: absolute;
      top: calc(50% - 8px);
      width: 1px;
      height: 16px;
      background: #303133;
    }
  }
}

.home {
  height: 100vh;

  .nav {
    .locale {
      font-size: 1.5rem;
    }
  }

  .search-container {
    .type-container {
      :deep(.el-radio-button__inner) {
        display: block;
      }
    }
  }

}
</style>
