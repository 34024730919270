<template>
  <div class="container">
    <div class="title">
      <h2 class="text-center">
        PRESENTATION APPLICATION FORM
      </h2>
      <h2 class="text-center">
        路演申请表
      </h2>
    </div>
    <el-tabs
      v-model="activeName"
      class="tabs"
    >
      <el-tab-pane
        label="COMPANY INFORMATION 公司信息"
        name="first"
      >
        <el-card class="box-card mt-3">
          <div
            slot="header"
            class="clearfix"
          >
            <span>Step 1/3. COMPANY INFORMATION 公司信息</span>
          </div>

          <el-form
            ref="form"
            :model="form"
            label-width="200px"
            :rules="formRules"
            :label-position="labelPosition"
          >
            <el-form-item
              :label="$tc('apply.company')"
              prop="company"
              required
            >
              <el-input
                v-model="form.company"
                :placeholder="$tc('apply.company')"
              />
            </el-form-item>
            <el-form-item
              :label="$tc('apply.country')"
              prop="country"
            >
              <el-select
                v-model="form.country"
                class="w-100"
                filterable
                :placeholder="$tc('apply.country')"
              >
                <el-option
                  v-for="country in options.countries"
                  :key="country"
                  :label="country"
                  :value="country"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$tc('apply.company_introduction')"
              prop="company_introduction"
            >
              <el-input
                v-model="form.company_introduction"
                rows="4"
                :placeholder="$tc('apply.company_introduction')"
                type="textarea"
              />
            </el-form-item>
            <el-form-item
              :label="$tc('apply.company_objective')"
              prop="company_objective"
            >
              <el-input
                v-model="form.company_objective"
                rows="4"
                :placeholder="$tc('apply.placeholder.company_objective')"
                type="textarea"
              />
            </el-form-item>
            <el-form-item
              :label="$tc('apply.public_or_private')"
              prop="public_or_private"
            >
              <el-switch
                v-model="form.public_or_private"
              />
            </el-form-item>
            <el-form-item
              v-if="form.public_or_private"
              :label="$tc('apply.market_cap')"
              prop="market_cap"
            >
              <el-input
                v-model="form.market_cap"
                :placeholder="$tc('apply.market_cap')"
              >
                <template slot="prepend">
                  $(M)
                </template>
              </el-input>
            </el-form-item>
            <el-form-item
              v-else
              :label="$tc('apply.total_fund_raised')"
              prop="total_fund_raised"
            >
              <el-input
                v-model="form.total_fund_raised"
                :placeholder="$tc('apply.total_fund_raised')"
              >
                <template slot="prepend">
                  $(M)
                </template>
              </el-input>
            </el-form-item>
            <el-form-item
              :label="$tc('apply.funding_history')"
              prop="funding_history"
            >
              <el-input
                v-model="form.funding_history"
                :placeholder="$tc('apply.placeholder.funding_history')"
                type="textarea"
                rows="4"
              />
            </el-form-item>
            <el-form-item
              :label="$tc('apply.project_highlights')"
              prop="project_highlights"
            >
              <el-input
                v-model="form.project_highlights"
                :placeholder="$tc('apply.placeholder.project_highlights')"
                type="textarea"
                rows="4"
              />
            </el-form-item>
            <el-form-item
              :label="$tc('apply.website')"
              prop="website"
            >
              <el-input
                v-model="form.website"
                :placeholder="$tc('apply.website')"
              >
                <template slot="prepend">
                  https://
                </template>
              </el-input>
            </el-form-item>
            <el-form-item
              :label="$tc('apply.attachment')"
              prop="attachment"
            >
              <el-input
                v-model="form.attachment"
                disabled
                :placeholder="$tc('apply.attachment_placeholder')"
              >
                <template slot="append">
                  <el-button
                    :loading="uploading"
                    @click="$refs.attachment.click()"
                  >
                    {{ $tc('apply.click_to_upload') }}
                  </el-button>
                </template>
              </el-input>
              <input
                ref="attachment"
                type="file"
                hidden
                accept="*"
                @change="uploadAttachment()"
              >
            </el-form-item>
            <el-form-item
              :label="$tc('apply.pre_recorded')"
              prop="pre_recorded_link"
            >
              <el-input
                v-model="form.pre_recorded_link"
                :placeholder="$tc('apply.pre_recorded_placeholder')"
              >
                <template slot="prepend">
                  https://
                </template>
              </el-input>
            </el-form-item>
            <el-form-item
              :label="$tc('apply.referrer')"
              prop="referrer"
            >
              <el-input
                v-model="form.referrer"
                :placeholder="$tc('apply.referrer_placeholder')"
              />
            </el-form-item>
          </el-form>
        </el-card>
      </el-tab-pane>
      <el-tab-pane
        label="ASSET INFORMATION 路演资产信息"
        name="second"
      >
        <el-card class="box-card mt-3">
          <div
            slot="header"
            class="clearfix"
          >
            <span>Step 2/3. ASSET INFORMATION 路演资产信息</span>
          </div>
          <div
            v-for="(item,index) in assets"
            :key="index"
          >
            <el-divider content-position="left">
              Asset{{ index + 1 }} Information
              资产{{ index + 1 }}信息
              <el-button
                v-if="index>0"
                icon="fas el-icon-fa-times"
                type="text"
                @click="removeAsset(index)"
              />
            </el-divider>
            <el-form
              ref="asset"
              :model="item"
              label-width="200px"
              :rules="assetRules"
              :label-position="labelPosition"
            >
              <el-form-item
                :label="$tc('apply.asset.asset_name')"
                prop="asset_name"
                required
              >
                <el-input
                  v-model="item.asset_name"
                  :placeholder="$tc('apply.asset.asset_name')"
                />
              </el-form-item>
              <el-form-item
                :label="$tc('apply.asset.disease')"
                prop="diseases"
                required
              >
                <el-select
                  v-model="item.diseases"
                  multiple
                  :placeholder="$tc('apply.asset.disease')"
                  class="w-100"
                >
                  <el-option
                    v-for="disease in options.diseases"
                    :key="disease[0]"
                    :label="$tc(disease[0])"
                    :value="disease[0]"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                :label="$tc('apply.asset.indication')"
                prop="indication"
                required
              >
                <el-input
                  v-model="item.indication"
                  :placeholder="$tc('apply.asset.indication')"
                />
              </el-form-item>
              <el-form-item
                :label="$tc('apply.asset.phase')"
                prop="phase"
                required
              >
                <el-select
                  v-model="item.phase"
                  clearable
                  filterable
                  class="w-100"
                  :placeholder="$tc('apply.asset.phase')"
                >
                  <el-option
                    v-for="phase in options.phases"
                    :key="phase"
                    :label="phase"
                    :value="phase"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                prop="type"
                :label="$tc('apply.asset.type')"
              >
                <el-select
                  v-model="item.type"
                  clearable
                  filterable
                  class="w-100"
                  :placeholder="$tc('apply.asset.type')"
                >
                  <el-option
                    v-for="type in options.asset_type"
                    :key="type"
                    :label="type"
                    :value="type"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="item.type === 'Others'"
                prop="other_type_desc"
                :label="$tc('apply.asset.other_type')"
              >
                <el-input
                  v-model="item.other_type_desc"
                  :placeholder="$tc('apply.asset.other_type')"
                />
              </el-form-item>
              <el-form-item
                prop="demand_types"
                required
                :label="$tc('apply.asset.demand_type')"
              >
                <el-select
                  v-model="item.demand_types"
                  class="w-100"
                  multiple
                  :placeholder="$tc('apply.asset.demand_type')"
                >
                  <el-option
                    v-for="demand_type in options.demand_types"
                    :key="demand_type"
                    :label="demand_type"
                    :value="demand_type"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                :label="$tc('apply.asset.moa')"
                prop="moa"
              >
                <el-input
                  v-model="item.moa"
                  type="textarea"
                  :placeholder="$tc('apply.placeholder.asset.moa')"
                  rows="4"
                />
              </el-form-item>
              <el-form-item
                :label="$tc('apply.asset.asset_description')"
                prop="asset_description"
              >
                <el-input
                  v-model="item.asset_description"
                  type="textarea"
                  :placeholder="$tc('apply.placeholder.asset.asset_description')"
                  rows="4"
                />
              </el-form-item>
              <el-form-item
                :label="$tc('apply.asset.licensing_right_available')"
                prop="licensing_right_available"
              >
                <el-select
                  v-model="item.licensing_right_available"
                  class="w-100"
                  multiple
                  :placeholder="$tc('apply.asset.licensing_right_available')"
                >
                  <el-option
                    v-for="right_available in options.right_available"
                    :key="right_available"
                    :value="right_available"
                    :label="right_available"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                :label="$tc('apply.asset.ip_status')"
                prop="ip_status"
              >
                <el-input
                  v-model="item.ip_status"
                  :placeholder="$tc('apply.placeholder.asset.ip_status')"
                  type="textarea"
                  rows="4"
                />
              </el-form-item>
              <el-form-item
                :label="$tc('apply.asset.notes')"
                prop="notes"
              >
                <el-input
                  v-model="item.notes"
                  :placeholder="$tc('apply.asset.notes')"
                  type="textarea"
                  rows="4"
                />
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-tab-pane>
      <el-tab-pane
        label="Contact Information联系方式"
        name="third"
      >
        <el-card class="box-card mt-3">
          <div
            slot="header"
            class="clearfix"
          >
            <span>Step 3/3. Contact Information联系方式</span>
          </div>
          <el-divider content-position="left">
            Primary Contact 对接人
          </el-divider>
          <el-form
            ref="primary_contact"
            :model="primary_contact"
            :rules="memberRule"
            label-width="200px"
            :label-position="labelPosition"
          >
            <el-form-item
              :label="$tc('apply.member.name')"
              prop="name"
              required
            >
              <el-input
                v-model="primary_contact.name"
                :placeholder="$tc('apply.member.name')"
              />
            </el-form-item>
            <el-form-item
              :label="$tc('apply.member.avatar')"
              prop="avatar"
            >
              <img
                v-if="primary_contact.avatar"
                class="avatar"
                :src="primary_contact.avatar"
                alt="avatar"
                @click="$refs.primary_contact_avatar.click()"
              >
              <div
                v-else
                class="avatar-tip"
                @click="$refs.primary_contact_avatar.click()"
              >
                {{ $tc('apply.member.avatar_upload') }}
              </div>
              <input
                ref="primary_contact_avatar"
                type="file"
                hidden
                accept="image/*"
                @change="changeAvatar($refs.primary_contact_avatar,primary_contact)"
              >
            </el-form-item>
            <el-form-item :label="$tc('apply.member.job_title')">
              <el-input
                v-model="primary_contact.job_title"
                :placeholder="$tc('apply.member.job_title')"
              />
            </el-form-item>
            <el-form-item
              :label="$tc('apply.member.email')"
              prop="email"
              required
            >
              <el-input
                v-model="primary_contact.email"
                :placeholder="$tc('apply.member.email')"
              />
            </el-form-item>
            <el-form-item
              :label="$tc('apply.member.phone')"
              prop="phone"
              required
            >
              <el-input
                v-model="primary_contact.phone"
                :placeholder="$tc('apply.member.phone')"
              />
            </el-form-item>
            <el-form-item :label="$tc('apply.member.region')">
              <el-select
                v-model="primary_contact.region"
                class="w-100"
                filterable
                :placeholder="$tc('apply.member.region')"
              >
                <el-option
                  v-for="country in options.countries"
                  :key="country"
                  :label="country"
                  :value="country"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$tc('apply.member.time_zone')">
              <el-select
                v-model="primary_contact.time_zone"
                :placeholder="$tc('apply.member.time_zone')"
                class="w-100"
                filterable
              >
                <el-option
                  v-for="item in options.timezone"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-form>
          <div
            v-for="(item,index) in presenters"
            :key="index"
          >
            <el-divider content-position="left">
              Presenter{{ index + 1 }} Information
              讲者{{ index + 1 }}信息
              <el-button
                v-if="index>0"
                icon="fas el-icon-fa-times"
                type="text"
                @click="removePresenter(index)"
              />
            </el-divider>
            <el-form
              ref="presenter"
              :model="item"
              label-width="200px"
              :rules="memberRule"
              :label-position="labelPosition"
            >
              <el-form-item
                v-if="index===0"
                :label="$tc('apply.member.same')"
              >
                <el-switch
                  v-model="item.same"
                />
              </el-form-item>
              <el-form-item
                v-show="!item.same"
                prop="name"
                :label="$tc('apply.member.name')"
                required
              >
                <el-input
                  v-model="item.name"
                  :placeholder="$tc('apply.member.name')"
                />
              </el-form-item>
              <el-form-item
                v-show="!item.same"
                :label="$tc('apply.member.avatar')"
                prop="avatar"
              >
                <img
                  v-if="item.avatar"
                  alt="avatar"
                  class="avatar"
                  :src="item.avatar"
                  @click="$refs.presenter_avatar[index].click()"
                >
                <div
                  v-else
                  class="avatar-tip"
                  @click="$refs.presenter_avatar[index].click()"
                >
                  {{ $tc('apply.member.avatar_upload') }}
                </div>
                <input
                  ref="presenter_avatar"
                  type="file"
                  hidden
                  accept="image/*"
                  @change="changeAvatar($refs.presenter_avatar[index],item)"
                >
              </el-form-item>
              <el-form-item
                v-show="!item.same"
                :label="$tc('apply.member.job_title')"
                prop="job_title"
              >
                <el-input
                  v-model="item.job_title"
                  :placeholder="$tc('apply.member.job_title')"
                />
              </el-form-item>
              <el-form-item
                v-show="!item.same"
                :label="$tc('apply.member.email')"
                prop="email"
                required
              >
                <el-input
                  v-model="item.email"
                  :placeholder="$tc('apply.member.email')"
                />
              </el-form-item>
              <el-form-item
                v-show="!item.same"
                prop="phone"
                :label="$tc('apply.member.phone')"
                required
              >
                <el-input
                  v-model="item.phone"
                  :placeholder="$tc('apply.member.phone')"
                />
              </el-form-item>
              <el-form-item
                v-show="!item.same"
                :label="$tc('apply.member.region')"
              >
                <el-select
                  v-model="item.region"
                  class="w-100"
                  filterable
                  :placeholder="$tc('apply.member.region')"
                >
                  <el-option
                    v-for="country in options.countries"
                    :key="country"
                    :label="country"
                    :value="country"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                v-show="!item.same"
                :label="$tc('apply.member.time_zone')"
              >
                <el-select
                  v-model="item.time_zone"
                  class="w-100"
                  filterable
                  :placeholder="$tc('apply.member.time_zone')"
                >
                  <el-option
                    v-for="timezone in options.timezone"
                    :key="timezone"
                    :label="timezone"
                    :value="timezone"
                  />
                </el-select>
              </el-form-item>
              <el-form-item :label="$tc('apply.member.biography_en')">
                <el-input
                  v-model="item.biography_en"
                  :placeholder="$tc('apply.member.biography_en')"
                  type="textarea"
                  rows="4"
                />
              </el-form-item>
              <el-form-item :label="$tc('apply.member.biography_cn')">
                <el-input
                  v-model="item.biography_cn"
                  :placeholder="$tc('apply.member.biography_cn')"
                  type="textarea"
                  rows="4"
                />
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-tab-pane>
    </el-tabs>
    <div
      v-if="activeName==='second'"
      class="add-container mt-3 "
    >
      <el-button
        v-if="assets.length < 10"
        icon="fas el-icon-fa-plus"
        type="primary"
        @click="addNewAsset"
      >
        {{ $tc('apply.asset.add') }}
      </el-button>
    </div>
    <div
      v-if="activeName==='third'"
      class="add-container mt-3"
    >
      <el-button
        v-if="presenters.length < 3"
        icon="fas el-icon-fa-plus"
        type="primary"
        @click="addNewPresenter"
      >
        {{ $tc('apply.member.add') }}
      </el-button>
    </div>
    <div class="mt-3 text-center">
      <el-button
        v-if="activeName==='third'"
        type="primary"
        :loading="loading"
        @click="apply()"
      >
        {{ $tc('apply.confirm') }}
      </el-button>
      <el-button
        v-else
        type="primary"
        :loading="loading"
        @click="next()"
      >
        {{ $tc('apply.next') }}
      </el-button>
    </div>
    <div class="line p-3" />
    <div class="help">
      <p>If you have any question, please feel free to contact:</p>
      <p>Wendi Xiang</p>
      <p>YAFO Life Sciences (Shanghai)</p>
      <p>Mobile: +86-13733685995</p>
      <p>
        Email:
        <a
          class="link"
          target="_blank"
          href="mailto:wxiang@yafocapital.com"
        >
          wxiang@yafocapital.com
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator'
import momentTZ from 'moment-timezone'
import _ from 'lodash'
import * as countryList from '@/utils/country-list'
import { getOptions, apply } from '@/api/assets'
import { upload } from '@/api/util'

const BLANK_ASSET = {
  asset_name: '',
  diseases: [],
  demand_types: [],
  indication: '',
  phase: '',
  type: '',
  other_type_desc: '',
  moa: '',
  asset_description: '',
  licensing_right_available: '',
  ip_status: '',
  notes: ''
}

const BLANK_PRESENTER = {
  same: false,
  name: '',
  job_title: '',
  email: '',
  avatar: null,
  phone: '',
  region: '',
  time_zone: momentTZ.tz.guess(),
  biography_en: '',
  biography_cn: '',
  role: 'presenter'
}

export default @Component({
  beforeRouteLeave (to, from, next) {
    if (this.dirty) {
      const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
      if (answer) {
        next()
      } else {
        next(false)
      }
      this.cacheInformation()
    } else {
      next()
    }
  }
})
class AssetsApply extends Vue {
  activeName = 'first'
  uploading = false
  options = {
    timezone: momentTZ.tz.names(),
    countries: countryList.getNames(),
    asset_type: [
      'Small molecule',

      'Antibody',
      'Medical device',
      'Others'
    ],
    demand_types: [
      'Licensing out',
      'Licensing in',
      'Co-development',
      'Distribution',
      'Fundraising',
      'Other'
    ],
    diseases: [],
    phases: [],
    right_available: [
      'China',
      'ex-China',
      'Global',
      'Africa (excl MENA)',
      'Australia/New Zealand',
      'Canada',
      'Commonwealth of Independent States (CIS)',
      'Europe',
      'Japan',
      'Middle East, North Africa (MENA)',
      'South/Central America/Mexico',
      'South/South East Asia (excl China, Japan)',
      'USA'
    ]
  }

  dirty = false
  loading = false
  labelPosition = 'right'

  form = {
    company: '',
    country: '',
    company_introduction: '',
    company_objective: '',
    public_or_private: false,
    market_cap: 0,
    total_fund_raised: 0,
    funding_history: '',
    project_highlights: '',
    website: '',
    pre_recorded_link: '',
    attachment: '',
    referrer: ''
  }

  primary_contact = {
    name: '',
    job_title: '',
    email: '',
    phone: '',
    region: '',
    time_zone: momentTZ.tz.guess(),
    role: 'primary_contact'
  }

  assets = [
    _.cloneDeep(BLANK_ASSET)
  ]

  presenters = [
    _.cloneDeep(BLANK_PRESENTER)
  ]

  formRules = {
    company: [
      {
        required: true,
        message: this.$tc('blank_error'),
        trigger: 'blur'
      }
    ]
  }

  assetRules = {
    asset_name: [
      {
        required: true,
        message: this.$tc('blank_error'),
        trigger: 'blur'
      }
    ],
    disease: [
      {
        required: true,
        message: this.$tc('blank_error'),
        trigger: 'change'
      }
    ],
    indication: [
      {
        required: true,
        message: this.$tc('blank_error'),
        trigger: 'blur'
      }
    ],
    phase: [
      {
        required: true,
        message: this.$tc('blank_error'),
        trigger: 'change'
      }
    ],
    demand_type: [
      {
        required: true,
        message: this.$tc('blank_error'),
        trigger: 'blur'
      }
    ]
  }

  memberRule = {
    name: [
      {
        required: true,
        message: this.$tc('blank_error'),
        trigger: 'blur'
      }
    ],
    email: [
      {
        required: true,
        message: this.$tc('blank_error'),
        trigger: 'blur'
      },
      {
        type: 'email',
        message: this.$tc('email_error'),
        trigger: 'blur'
      }
    ],
    phone: [
      {
        required: true,
        message: this.$tc('blank_error'),
        trigger: 'blur'
      }
    ]
  }

  @Watch('form', { deep: true })
  handleFormChange () {
    this.dirty = true
  }

  @Watch('form.country')
  handleCompanyCountryChange (data) {
    [this.primary_contact, ...this.presenters].forEach((contact) => {
      if (!contact.region) {
        contact.region = data
      }
    })
  }

  mounted () {
    if (window.innerWidth < 682) {
      this.labelPosition = 'top'
    }
    this.form.source = this.$route?.query?.source || ''
    this.getCacheInformation()
    this.getOptions()
  }

  async getOptions () {
    try {
      const { data } = await getOptions()
      this.addTranslate(data)
      this.options = { ...this.options, ...data }
    } catch (e) {
      this.$message.error(this.$tc('error.get_option_error'))
    }
  }

  addTranslate (data) {
    const enTranslate = {}
    const zhTranslate = {}

    data.diseases.forEach(item => {
      enTranslate[item[0]] = item[0]
      zhTranslate[item[0]] = item[1]
    })

    this.$i18n.mergeLocaleMessage('zh', zhTranslate)
    this.$i18n.mergeLocaleMessage('en', enTranslate)
  }

  addNewPresenter () {
    this.presenters.push(_.cloneDeep(BLANK_PRESENTER))
  }

  removePresenter (index) {
    this.presenters.splice(index, 1)
  }

  addNewAsset () {
    this.assets.push(_.cloneDeep(BLANK_ASSET))
  }

  removeAsset (index) {
    this.assets.splice(index, 1)
  }

  next () {
    switch (this.activeName) {
      case 'first':
        this.activeName = 'second'
        break
      case 'second':
        this.activeName = 'third'
        break
      default:
        break
    }
  }

  async apply () {
    const {
      market_cap,
      total_fund_raised,
      public_or_private
    } = this.form

    if (public_or_private) {
      if (isNaN(market_cap)) {
        return this.$message.error(this.$tc('apply.market_cap') + this.$tc('error.number_only'))
      }
    } else {
      if (isNaN(total_fund_raised)) {
        return this.$message.error(this.$tc('apply.total_fund_raised') + this.$tc('error.number_only'))
      }
    }

    this.loading = true
    if (this.presenters[0].same) {
      Object.keys(this.primary_contact).forEach(key => {
        this.$set(this.presenters[0], key, this.primary_contact[key])
      })
    }

    try {
      await Promise.all([
        ...this.$refs.presenter.map(item => item.validate()),
        ...this.$refs.asset.map(item => item.validate()),
        this.$refs.form.validate(),
        this.$refs.primary_contact.validate()])
      const data = _.cloneDeep(this.form)

      if (isNaN(data.market_cap)) {
        data.market_cap = 0
      }
      if (isNaN(data.total_fund_raised)) {
        data.total_fund_raised = 0
      }

      const products = _.cloneDeep(this.assets)
      products.forEach(item => {
        item.disease = item.diseases.join(',')
        delete item.diseases
        item.demand_types = item.demand_types.join(',')
        delete item.demand_types
      })
      data.products = products
      data.members = [this.primary_contact, ...this.presenters]
      data.published_at = null
      await apply(data)
      this.$message.success('' + this.$tc('apply_asset_success'))
      this.dirty = false
    } finally {
      this.loading = false
    }
  }

  async changeAvatar (source, member) {
    if (source.files.length > 0) {
      const file = source.files[0]
      await this.uploadSingleAvatar(file, member)
    }
  }

  async uploadSingleAvatar (file, member) {
    const primary_contact_avatar = await upload(file)
    this.$set(member, 'avatar', primary_contact_avatar[0].url)
  }

  async uploadAttachment () {
    const files = this.$refs.attachment.files
    if (files.length) {
      try {
        this.uploading = true
        const [attachment] = await upload(files[0])
        this.$set(this.form, 'attachment', attachment.url)
      } finally {
        this.uploading = false
      }
    }
  }

  cacheInformation () {
    localStorage.setItem('assets-form', JSON.stringify(this.form))
    localStorage.setItem('assets-products', JSON.stringify(this.assets))
    localStorage.setItem('assets-primary-contact', JSON.stringify(this.primary_contact))
    localStorage.setItem('assets-presenters', JSON.stringify(this.presenters))
  }

  getCacheInformation () {
    let form, assets, primary_contact, presenters
    try {
      form = JSON.parse(localStorage.getItem('assets-form'))
      assets = JSON.parse(localStorage.getItem('assets-products'))
      primary_contact = JSON.parse(localStorage.getItem('assets-primary-contact'))
      presenters = JSON.parse(localStorage.getItem('assets-presenters'))
    } catch (e) {
      console.log(e)
    }

    if (form) {
      this.form = form
    }
    if (assets) {
      this.assets = assets
    }
    if (primary_contact) {
      this.primary_contact = primary_contact
    }
    if (presenters) {
      this.presenters = presenters
    }
  }
}
</script>

<style lang="scss" scoped>

.tabs {
  ::v-deep .el-tabs__header {
    position: sticky;
    top: 61px;
    z-index: 9999;
    background-color: white;
  }
}

.help {
  color: #606266;
  font-size: 14px;
}

.add-container {
  text-align: center;
  position: sticky;
  bottom: 1rem;

  a {
    cursor: pointer;
    font-size: 14px;
    background: #db9620;
    padding: 0.7rem 1rem 0.7rem 2rem;
    color: white;

  }
}

.avatar, .attachment {
  height: 4rem;
  width: 4rem;
  border-radius: 4rem;
  cursor: pointer;
  object-fit: cover;

  &-tip {
    cursor: pointer;
  }
}
</style>
